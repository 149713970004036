/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import {
  number,
  arrayOf,
  shape,
  string,
  func,
  oneOfType,
  bool,
  oneOf
} from 'prop-types'
import { propOr, prop, toUpper, not, equals, length } from 'ramda'
import SliderSlick from 'react-slick'

import Icon from 'components/Icon'
import Link from 'components/Link'
import Picture from 'components/Picture'
import { sliderBrands } from 'utils/sliderConfig'

import styles from './BrandSlider.scss'

const WIDTH = 100
const HEIGHT = 100
const COUNT_CATALOG = 8
const COUNT_PAGES = 10
const COUNT_PRODUCT = 7
const TITLE_HOME = {
  tm: 'Бренды',
  license: 'Лицензии'
}
const TITLE = {
  tm: 'Товары по брендам',
  license: 'Товары по лицензиям'
}
const LINK_TYPE = {
  tm: 'brand',
  license: 'license'
}

export default class BrandSlider extends PureComponent {
  static propTypes = {
    fetchData: func,
    brands: arrayOf(
      shape({
        CODE: string,
        COUNT: number,
        FAVORITE: string,
        ID: string,
        LOGO: string,
        FILE_PATH: string,
        NAME: string
      })
    ).isRequired,
    page: string,
    type: oneOf(['tm', 'license']),
    sectionId: oneOfType([string, number]),
    isBrandsLoaded: bool
  }

  componentDidMount() {
    const { sectionId, page, isBrandsLoaded } = this.props
    if (equals(page, 'product') && isBrandsLoaded) {
      return null
    }
    return this.props.fetchData(sectionId)
  }

  componentDidUpdate(prevProps) {
    if (
      not(equals(prevProps.page, this.props.page)) ||
      not(equals(prevProps.sectionId, this.props.sectionId))
    ) {
      this.props.fetchData(this.props.sectionId)
    }
  }

  getLink = brand => {
    const { page, sectionId, type } = this.props
    const filterParams = Number.isNaN(Number(sectionId))
      ? `filters=marks:${toUpper(sectionId)}`
      : `section=${sectionId}`
    const brandId = prop('ID', brand)
    switch (page) {
      case 'home':
      case 'catalogList':
        return `/catalog/${LINK_TYPE[type]}/${brandId}`
      case 'catalog':
      case 'product':
        return `/catalog/${LINK_TYPE[type]}/${brandId}?${filterParams}`
      default:
        return `/catalog/${LINK_TYPE[type]}/${brandId}`
    }
  }

  renderBrand = item => (
    <Link
      to={this.getLink(item)}
      key={item.ID}
    >
      <div
        className={styles.brandItem}
        title={propOr('', 'NAME', item)}
        itemProp='brand'
      >
        <div
          className={cx(styles.brandImage, {
            [styles.brandName]: !propOr('', 'FILE_PATH', item)
          })}
          itemProp='image'
        >
          <Picture
            backgroundSize='contain'
            width={WIDTH}
            height={HEIGHT}
            imgClassName={styles.image}
            alt={propOr('', 'NAME', item)}
            src={propOr('', 'LOGO', item)}
            isLazy
          />
        </div>
      </div>
    </Link>
  )

  renderTitle = () => {
    const { page, type } = this.props
    const isHome = page === 'home'
    const title = isHome ? TITLE_HOME[type] : TITLE[type]
    if (isHome) {
      return (
        <Link to={`/${LINK_TYPE[type]}`}
          size='big'
          weight='bold'>
          {title}
        </Link>
      )
    }
    return title
  }

  render() {
    const { brands, page } = this.props

    if (equals(length(brands), 0)) {
      return null
    }
    let slidesCount
    if (equals(page, 'catalog')) {
      slidesCount = COUNT_CATALOG
    } else if (equals(page, 'product')) {
      slidesCount = COUNT_PRODUCT
    } else {
      slidesCount = COUNT_PAGES
    }

    return (
      <div
        className={styles.SliderWrapper}
        itemScope
        itemType='http://schema.org/Offer'
      >
        <div className={styles.titleWrapper}>{this.renderTitle()}</div>

        <SliderSlick
          dots={false}
          infinite={false}
          draggable={false}
          speed={500}
          slidesToShow={slidesCount}
          slidesToScroll={slidesCount}
          nextArrow={<Icon icon='next' />}
          prevArrow={<Icon icon='prev' />}
          className={styles.slider}
          responsive={sliderBrands}
          arrows
          lazyLoad
        >
          {brands.map(this.renderBrand)}
        </SliderSlick>
      </div>
    )
  }
}
