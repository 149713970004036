/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { array, oneOf, number, bool, string, func } from 'prop-types'
import qs from 'qs'
import { toLower, propOr, prop, and, includes, equals } from 'ramda'
import { Link } from 'react-router-dom'

import CopyPopup from 'components/CopyPopup'
import RubleSign from 'components/RubleSign'
import Tooltip from 'components/Tooltip'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'

import styles from './PropertyTable.scss'

const TITLES_VIEW = ['Страна', 'Бренд', 'Серия', 'Лицензия']

export default class PropertyTable extends PureComponent {
  static propTypes = {
    properties: array,
    isTitleNeed: bool,
    isLoyalty: bool,
    isBookmark: bool,
    onRemoveButton: func,
    handleHideModal: func,
    productSection: number,
    isRemoveButtonNeed: bool,
    bookmark: number,
    align: oneOf(['left', 'right', 'center']),
    mode: oneOf([
      'ProductList',
      'Base',
      'ProductOne',
      'ProductOneQuick',
      'ProductTabs'
    ]),
    groupType: string
  }

  static defaultProps = {
    mode: 'Base',
    onRemoveButton: () => {},
    handleHideModal: () => {},
    isTitleNeed: true,
    isLoyalty: false,
    properties: []
  }

  handleRemoveButtonClick = item => () => {
    const { onRemoveButton } = this.props
    onRemoveButton(item)
  }

  getFilterPropertyName = (str = '') => toLower(str)

  renderRemoveButton = item => (
    <Spacing margin='horizontal'>
      <Button
        color='lightBlue'
        icon='delete'
        shape='circle'
        size='tiny'
        onClick={this.handleRemoveButtonClick(item)}
      />
    </Spacing>
  )

  renderRow = (item, key) => {
    const {
      mode,
      isTitleNeed,
      isRemoveButtonNeed,
      align,
      productSection,
      isLoyalty,
      isBookmark,
      bookmark
    } = this.props
    const isPrice = propOr('', 'name', item) === 'PRICE'
    const itemValue = propOr('', 'value', item)
    const title = propOr('', 'title', item) || propOr('', 'TITLE', item)
    const value = itemValue || propOr('', 'VALUE', item)
    const description = prop('description', item)

    if (isTitleNeed && (!title || !value)) {
      return null
    }

    const isButton = propOr(false, 'button', item)
    if (isButton) {
      return (
        <CopyPopup key={value}
          text={`${title} скопирован`}
          value={value}>
          <button
            type='button'
            className={cx(styles.copyButton, styles[`tr${mode}`])}
            title={title}
          >
            <span
              title={title}
              className={cx(styles.propertyItem, styles[`tdLeft${mode}`])}
            >
              {title}
            </span>
            <span
              title={value}
              className={cx(styles.propertyItem, styles.copyButton_value)}
            >
              {value}
            </span>
          </button>
        </CopyPopup>
      )
    }
    const itemKey = this.getFilterPropertyName(propOr('', 'code', item))
    const itemId = propOr('', 'id', item)
    const isLink = !!itemKey && !!itemId && !isLoyalty
    const pathname = `${
      isBookmark ? `/bookmark/${bookmark}` : ''
    }/catalog/products/${productSection}/`
    const query = { p: 1, filters: `${itemKey}:${itemId}` }
    const linkTo = isLink
      ? { pathname, search: qs.stringify(query) }
      : undefined
    const itemLink = propOr('', 'link', item)
    const props = {
      to: itemLink || linkTo,
      onClick: this.props.handleHideModal
    }
    const Tag = isLink || (!!itemLink && !isLoyalty) ? Link : 'div'

    if (and(equals(mode, 'ProductOne'), includes(title, TITLES_VIEW))) {
      return null
    }

    return (
      <Tag
        key={key}
        {...props}
        className={cx(
          styles.trProperty, {
            [styles[`tr${mode}`]]: !!mode,
            [styles[`trProperty_align_${align}`]]: !!align
          }
        )}
      >
        {isTitleNeed && (
          <div
            title={title}
            className={cx(
              styles.propertyItem,
              styles.overflow,
              styles[`tdLeft${mode}`]
            )}
          >
            {title}
          </div>
        )}
        {description && (
          <div className={styles.description}>
            <Tooltip tooltip={description}>?</Tooltip>
          </div>
        )}
        <div
          className={cx(styles.propertyItem, styles[`align_${align}`], {
            [styles.tdRight]: mode === 'Base',
            [styles.tdRightProductOne]: mode === 'ProductOne',
            [styles.tdRightProductOneQuick]: mode === 'ProductOneQuick'
          })}
        >
          <div
            title={value}
            className={cx(styles.overflow, {
              [styles.compare]: this.props.groupType === 'compare'
            })}
          >
            {isRemoveButtonNeed && this.renderRemoveButton(item)}
            <span
              className={cx(styles.filterLink, {
                [styles.filterLinkTitle]: includes(title, TITLES_VIEW)
              })}
            >
              {`${itemValue} ${propOr('', 'unit', item)}` || '-'}
            </span>
            {isPrice && !!itemValue && <RubleSign />}
          </div>
        </div>
      </Tag>
    )
  }

  render() {
    const { properties, mode } = this.props
    return (
      <div
        className={cx(styles.PropertyTable, {
          [styles.PropertyTable_compare]: this.props.groupType === 'compare',
          [styles.PropertyTable_quickView]: mode === 'ProductOneQuick',
          [styles.PropertyTable_productList]: mode === 'ProductList'
        })}
        itemProp='additionalProperty'
        itemScope
        itemType='http://schema.org/PropertyValue'
        role='presentation'
      >
        <div className={styles.table}>{properties.map(this.renderRow)}</div>
      </div>
    )
  }
}
