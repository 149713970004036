/* eslint-disable complexity */
import {
  propOr,
  pathOr,
  curry,
  path,
  compose,
  fromPairs,
  map,
  props,
  prop,
  filter,
  propEq,
  head,
  toUpper,
  mergeRight,
  pick,
  mergeAll,
  pickAll,
  defaultTo,
  prepend,
  equals,
  toLower,
  unnest,
  flatten,
  sum,
  length,
  isEmpty,
  last,
  keys,
  insertAll
} from 'ramda'
import { createSelector } from 'reselect'

import config from 'config'
import renameKeys from 'helpers/objectModify'
import { getBasket, getBasketInfo, getLimitsCount } from 'helpers/products'

const MAX_GALLERY_ITEMS_CNT = 9
const GALLERY_ARROWS_CNT = 2
const DEFAULT_IMG_WIDTH = 53
const QUICK_VIEW_IMG_WIDTH = 73
const EMPTY_LIST_NOTICE = 'Не найдено ни одного товара'

export const noticeSelector = createSelector(
  state => state,
  state => propOr(EMPTY_LIST_NOTICE, 'notice', state)
)

export const getOrderList = state => state.orderList
export const getError = state => state.error

export const getBreadcrumbs = (type, items, bookmark = 0) => {
  if (!type || !items) {
    return []
  }
  const catLink = { title: 'Каталог', pathname: '/catalog' }
  const lastItemId = compose(propOr('', 'ID'), last)(items)
  if (type === 'section' && bookmark === 0) {
    return map(
      item => ({
        id: propOr('', 'ID', item),
        title: propOr('', 'TITLE', item),
        pathname:
          lastItemId === propOr('', 'ID', item)
            ? `/catalog/products/${propOr('', 'ID', item)}`
            : `/catalog/sections/${propOr('', 'ID', item)}`
      }),
      items
    )
  }
  if (type === 'section' && bookmark > 0) {
    return compose(
      prepend(catLink),
      map(item => ({
        id: propOr('', 'ID', item),
        title: propOr('', 'TITLE', item),
        pathname:
          lastItemId === propOr('', 'ID', item)
            ? `/bookmark/${bookmark}/catalog/products/${propOr('', 'ID', item)}`
            : `/bookmark/${bookmark}/catalog/sections/${propOr('', 'ID', item)}`
      }))
    )(items)
  }
  if (type === 'loyalty') {
    return map(
      item => ({
        id: propOr('', 'ID', item),
        title: propOr('', 'TITLE', item),
        pathname: propOr('', 'ID', item)
          ? `/loyalty/catalog/products/${propOr('', 'ID', item)}`
          : '/loyalty'
      }),
      items
    )
  }

  const title = pathOr('', [0, 'TITLE'], items)
  const id = pathOr('', [0, 'ID'], items)
  return {
    brand: [
      { title: 'Бренды', pathname: '/brand' },
      { title: 'Все товары бренда', pathname: `/catalog/brand/${id}` }
    ],
    license: [
      { title: 'Лицензии', pathname: '/license' },
      { title: 'Все товары лицензии', pathname: `/catalog/license/${id}` }
    ],
    country: [
      catLink,
      { title: 'Страна', link: false },
      { title, pathname: `/catalog/country/${id}` }
    ],
    ruler: [
      catLink,
      { title: 'Серия', link: false },
      { title, pathname: `/catalog/ruler/${id}` }
    ]
  }[type]
}

export const getItemById = curry((id, state) =>
  pathOr({}, ['itemsById', id], state)
)
export const getItemOrderById = curry((id, state) =>
  pathOr({}, ['itemsOrderById', id], state)
)
export const getItemsById = curry((ids, state) =>
  ids.map(id => getItemById(id, state))
)
export const getItemCountById = curry(
  (id, state) => getItemOrderById(id, state).amount
)
export const getItemMinById = curry((id, state) =>
  pathOr(1, ['itemsById', id, 'MULTIPLICITY', 'MIN'], state)
)

export const selectProducts = createSelector(
  state => state.itemsIdList,
  state => state.itemsById,
  (idList, items) => idList.map(id => items[id])
)

export const makeGetItemOrder = () =>
  createSelector(
    (state, { id, isNewApi }) =>
      getItemById(id, state[isNewApi ? 'products' : 'productList']),
    (state, { id }) => getItemOrderById(id, state.products),
    (item, order) => ({
      ...order,
      basket: getBasket(item, order.basketType),
      isBookmark:
        Array.isArray(order.basketType) && order.basketType[0] === 'bookmark'
    })
  )

const entitiesSelector = createSelector(
  state => state,
  state => pathOr({}, ['metadata', 'ENTITIES'], state)
)
export const marksSelector = createSelector(entitiesSelector, entities =>
  prop('MARKS', entities)
)

export const bookmarksSelector = createSelector(entitiesSelector, entities =>
  propOr({}, 'BOOKMARKS', entities)
)

export const offersSelector = createSelector(entitiesSelector, entities =>
  propOr({}, 'OFFERS', entities)
)

export const storesSelector = createSelector(entitiesSelector, entities =>
  propOr({}, 'STORES', entities)
)

export const productSelector = createSelector(
  ({ products }) => prop('itemsById', products),
  ({ products }) => prop('itemsOrderById', products),
  ({ productList }) => prop('itemsById', productList),
  (_, ownProps) => pick(['id', 'type'], ownProps),
  (products, productsOrder, productList, { id, type }) =>
    type === 'basket'
      ? mergeRight(propOr({}, id, productList), propOr({}, id, productsOrder))
      : mergeAll([
        propOr({}, id, productList),
        propOr({}, id, products),
        propOr({}, id, productsOrder)
      ])
)

export const basketTypeSelector = createSelector(
  (_, ownProps) => propOr('main', 'catalog', ownProps),
  (_, ownProps) => propOr(0, 'cartId', ownProps),
  (basketType, cartId) => [toUpper(basketType), cartId]
)

export const activeStoreSelector = createSelector(
  productSelector,
  basketTypeSelector,
  (product, basketType) => {
    const productActiveStore = prop('activeStore', product)
    if (productActiveStore) {
      return productActiveStore
    }
    const productInBasket = propOr(
      0,
      'STORE',
      getBasketInfo(product, basketType)
    )
    if (productInBasket) {
      return productInBasket
    }
    const productSelected = compose(
      path([0, 'CODE']),
      filter(item => equals(true, propOr(false, 'SELECTED', item))),
      propOr([], 'STORES')
    )(product)
    if (productSelected) {
      return productSelected
    }
    const productStoreMain = compose(
      path([0, 'CODE']),
      filter(item => propEq('IS_MAIN', true, item)),
      propOr([], 'STORES')
    )(product)
    if (productStoreMain) {
      return productStoreMain
    }
    const productStoreFirst = path(['STORES', 0, 'CODE'], product)
    if (productStoreFirst) {
      return productStoreFirst
    }
    return ''
  }
)

export const orderSelector = createSelector(
  products => products,
  (_, { id }) => id,
  (products, id) => pathOr({}, ['itemsOrderById', id], products)
)

export const remainSelector = createSelector(
  item => item,
  item =>
    compose(
      compose(fromPairs, map(props(['CODE', 'REMAIN']))),
      propOr([], 'STORES')
    )(item)
)

export const currentStoreSelector = createSelector(
  item => item,
  (_, activeStore) => activeStore,
  (item, activeStore) => {
    if (isEmpty(item)) {
      return {}
    }
    return compose(
      head,
      filter(i => propEq('CODE', activeStore, i)),
      propOr([], 'STORES'),
      defaultTo({})
    )(item)
  }
)

export const storeSelector = createSelector(
  currentStoreSelector,
  currentStore => {
    const { min } = getLimitsCount(currentStore)
    return {
      isMain: !!propOr(false, 'IS_MAIN', currentStore),
      remain: propOr(0, 'REMAIN', currentStore),
      forPoints: !!propOr(false, 'FOR_POINTS', currentStore),
      min,
      step: Number(pathOr(1, ['MULTIPLICITY', 'STEP'], currentStore)),
      onlyMyltiplicity: !!propOr(false, 'ONLY_MULTIPLICITY', currentStore)
    }
  }
)

export const checkedSelector = createSelector(
  products => prop('itemsCheckedMap', products),
  checkedProducts => filter(item => item, checkedProducts)
)

export const amountSelector = createSelector(orderSelector, productOrder =>
  prop('amount', productOrder)
)

export const buySelector = createSelector(orderSelector, productOrder =>
  compose(
    map(item => defaultTo(false, item)),
    pickAll([
      'basketType',
      'isNotMultiple',
      'amount',
      'isUpdating',
      'isForPoints'
    ])
  )(productOrder)
)

const itemIdSelector = createSelector(
  (_, ownProps) => ownProps,
  ownProps => prop('id', ownProps)
)
const itemByIdSelector = createSelector(
  state => state,
  state => prop('itemsById', state)
)

export const itemSelector = createSelector(
  itemByIdSelector,
  itemIdSelector,
  // state => pathOr([], ['itemsIdByGroup', 'waitList'], state),
  (items, id) => propOr({}, id, items)
)

const productFilesSelector = createSelector(
  item => prop('ID', item),
  (_, productList) => prop('files', productList),
  (id, files) => prop(id, files)
)

export const productPhotosSelector = createSelector(
  productFilesSelector,
  files =>
    compose(
      map(photo => photo && prop('PATH', photo)),
      propOr([], 'photos')
    )(files)
)

export const productCertsSelector = createSelector(
  productFilesSelector,
  files => prop('cert', files)
)

export const productSpriteSelector = createSelector(
  productFilesSelector,
  files => propOr('', 'sprite', files)
)

export const preparePropertySelector = createSelector(
  item => [
    {
      ID: pathOr('', ['COUNTRY', 'ID'], item),
      TITLE: 'Страна',
      VALUE: pathOr('', ['COUNTRY', 'VALUE'], item),
      LINK: `/catalog/country/${pathOr('', ['COUNTRY', 'ID'], item)}`
    }
  ],
  item => [
    {
      ID: pathOr('', ['TM', 'ID'], item),
      TITLE: 'Бренд',
      VALUE: pathOr('', ['TM', 'VALUE'], item),
      LINK: `/catalog/brand/${pathOr('', ['TM', 'ID'], item)}`
    }
  ],
  item => [
    {
      ID: pathOr('', ['RULER', 'ID'], item),
      TITLE: 'Серия',
      VALUE: pathOr('', ['RULER', 'VALUE'], item),
      LINK: `/catalog/ruler/${pathOr('', ['RULER', 'ID'], item)}`
    }
  ],
  item => [
    {
      ID: pathOr('', ['LICENSE', 'ID'], item),
      TITLE: 'Лицензия',
      VALUE: pathOr('', ['LICENSE', 'VALUE'], item),
      LINK: `/catalog/license/${pathOr('', ['LICENSE', 'ID'], item)}`
    }
  ],
  item => propOr([], 'PROPERTIES', item),

  (country, brand, ruler, license, properties) =>
    compose(
      map(renameKeys(toLower)),
      filter(item => prop('VALUE', item)),
      unnest
    )([country, brand, ruler, license, properties])
)

export const prepareParamsSelector = createSelector(
  state => state,
  state => {
    const params = propOr([], 'PARAMS', state)

    return map(
      item => ({
        title: prop('TITLE', item),
        value: prop('VALUE', item),
        description: prop('DESCRIPTION', item),
        button: propOr(false, 'CAN_COPY', item)
      }),
      params
    )
  }
)

export const productTabsSelector = createSelector(
  state => state,
  state => {
    if (propOr(false, 'isQuickView', state)) {
      return [
        {
          id: 'description',
          title: 'Описание'
        },
        {
          id: 'params',
          title: 'Характеристики'
        }
      ]
    }
    const analogsData = propOr({}, 'analogsCarousels', state)
    const analogsTabs = compose(
      map(key => ({
        id: key,
        title: pathOr('', [key, 'title'], analogsData)
      })),
      keys
    )(analogsData)
    const tabs = [
      {
        id: 'description',
        title: 'Информация о товаре'
      },
      {
        id: 'brands',
        title: 'Подборка по брендам'
      }
    ]
    /* Отзывы о товаре закомментировать */
    if (config.formIo.isActive && false) {
      tabs.push({
        id: 'productreview',
        title: 'Отзывы',
        count: propOr(null, 'feedbackCount', state)
      })
    }
    return insertAll(1, analogsTabs, tabs)
  }
)

const productVideoSelector = createSelector(
  item => prop('PRODUCT_VIDEO', item),
  video => (video ? ['/images/video/video.png'] : [])
)

const productPhotoSelector = createSelector(
  item => prop('DETAIL_PICTURE_PATH', item),
  photo => (photo ? [photo] : [])
)

export const productGalleryPhotoSelector = createSelector(
  productPhotoSelector,
  productPhotosSelector,
  productSpriteSelector,
  productVideoSelector,
  (detailPhoto, photos, sprite, video) => ({
    photos: flatten([detailPhoto, photos]),
    video,
    sprite
  })
)

export const productGallerySettingsSelector = createSelector(
  items => propOr([], 'photos', items),
  items => propOr([], 'video', items),
  items => propOr('', 'sprite', items),
  items => propOr(false, 'isQuickView', items),
  (photos, video, sprite, isQuickView) => {
    const videosCount = length(video)
    const photosCount = length(photos)
    const isShowSprite = !isEmpty(sprite)
    const itemsCount = sum([photosCount, videosCount, isShowSprite])
    const isShowControls = itemsCount > MAX_GALLERY_ITEMS_CNT
    const imagesWidth = !isQuickView ? DEFAULT_IMG_WIDTH : QUICK_VIEW_IMG_WIDTH
    const slidesToShow = isShowControls
      ? MAX_GALLERY_ITEMS_CNT - videosCount - isShowSprite - GALLERY_ARROWS_CNT
      : photosCount

    return {
      isShowControls,
      slidesToShow,
      slidesToScroll: slidesToShow,
      sliderWidth: slidesToShow * imagesWidth,
      isShowGallery: !!itemsCount
    }
  }
)

export const galleryModalSettingsSelector = createSelector(
  items => propOr([], 'photos', items),
  photos => {
    const photosCount = length(photos)
    const isShowControls = photosCount > 1
    return {
      isShowControls,
      slidesToShow: photosCount,
      slidesToScroll: photosCount,
      sliderWidth: photosCount * DEFAULT_IMG_WIDTH,
      isShowGallery: true
    }
  }
)

export const productGallerySpriteSelector = createSelector(
  item => prop('ID', item),
  (_, products) => propOr({}, 'sprite', products),
  (id, sprite) => propOr({}, id, sprite)
)

export const redirectIsLoadedSelector = createSelector(
  state => state,
  state => pathOr('', ['toRedirect', 'isLoaded'], state)
)

export const redirectIdSelector = createSelector(
  state => state,
  state => pathOr('', ['toRedirect', 'productId'], state)
)

export const productModalSelector = createSelector(
  state => state,
  state => pathOr('', ['productModal'], state)
)

export const analogsModalSelector = createSelector(
  state => state,
  state => propOr('', 'analogsModal', state)
)

export const productBarcodeModalSelector = createSelector(
  state => state,
  state => pathOr('', ['productBarcodeModal'], state)
)

export const descriptionSelector = createSelector(
  state => state,
  state => pathOr('', ['metadata', 'NAV', 'DESCRIPTION'], state)
)
