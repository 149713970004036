/* eslint-disable complexity */
import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

const initialState = {
  isLoaded: false,
  data: [],
  sections: {},
  manufactures: {},
  filter: {}
}

export {
  getIsLoaded,
  getIsLoading,
  getIsLoadedBrandsSelector,
  getIsLoadingBrandsSelector,
  brandsSelector,
  brandsDataSelector,
  brandsTotalDataSelector,
  brandsSortedSelector,
  brandsGroupsSelector,
  brandsFilteredSelector,
  brandsLangSelector,
  filterSymbolSelector,
  filterSectionSelector,
  manufacturesStrategicSelector,
  isLoadedManufacturesSelector
} from './selector'

export const fetchBrands = createAction('brands/FETCH_BRANDS')
export const fetchBrandsSuccess = createAction('brands/FETCH_BRANDS_SUCCESS')
export const fetchBrandsFailure = createAction('brands/FETCH_BRANDS_FAILURE')

// стратегические производители
export const fetchManufactures = createAction('brands/FETCH_MANUFACTURES')
export const fetchManufacturesSuccess = createAction(
  'brands/FETCH_MANUFACTURES_SUCCESS'
)
export const fetchManufacturesFailure = createAction(
  'brands/FETCH_MANUFACTURES_FAILURE'
)

export const request =
  ({ clientApi }) =>
    ({ type = 'tm', ...params }) =>
      clientApi
        .get(`/v3/catalog/main/directories/${type}/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(data => fetchBrandsSuccess({ ...data, ...params, type }))
        .catch(data => fetchBrandsFailure({ ...data, ...params, type }))

export const requestManufactures =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/catalog/main/directories/manufacture/', {
          params: {
            ...params,
            'mark[]': 'STRATEGIC',
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchManufacturesSuccess)
        .catch(fetchManufacturesFailure)

const handleFetchBrands = (state, params, { clientApi }) => {
  const effects = []
  effects.push(Effects.promise(request({ clientApi }), params))
  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.batch(effects)
  )
}

const handleFetchBrandsSuccess = (state, payload) => {
  const data = R.pathOr([], ['data', 'response', 'ITEMS'], payload)
  const type = R.pathOr([], ['type'], payload)
  const groups = R.pathOr(
    {},
    ['data', 'response', 'ENTITIES', 'GROUPS'],
    payload
  )
  const section = R.propOr('', 'section_id', payload)
  const mark = R.propOr('', 'mark_code', payload)
  const store = R.propOr('', 'store', payload)
  if (!R.isEmpty(section || store || mark)) {
    return {
      ...state,
      sections: {
        ...state.sections,
        [store || section || mark]: {
          isLoaded: true,
          isLoading: false,
          data,
          groups
        }
      }
    }
  }
  return {
    ...state,
    [type]: {
      isLoading: false,
      isLoaded: true,
      data,
      groups
    }
  }
}

const handleFetchBrandsFailure = (state, payload) => {
  const section = R.propOr('', 'section_id', payload)
  const store = R.propOr('', 'store', payload)
  return {
    ...state,
    sections: {
      ...state.sections,
      [store || section]: {
        isLoading: false,
        isLoaded: true
      }
    },
    error: getErrorMessage(payload)
  }
}

const handleFetchManufactures = (state, params, { clientApi }) => {
  const effects = []
  effects.push(Effects.promise(requestManufactures({ clientApi }), params))
  return loop(
    {
      ...state,
      manufactures: {
        data: [],
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.batch(effects)
  )
}

const handleFetchFetchManufacturesSuccess = (state, payload) => ({
  ...state,
  manufactures: {
    isLoading: false,
    isLoaded: true,
    data: R.pathOr([], ['data', 'response', 'ITEMS'], payload),
    groups: R.pathOr({}, ['data', 'response', 'ENTITIES', 'GROUPS'], payload)
  }
})

const handleFetchManufacturesFailure = (state, payload) => ({
  ...state,
  manufactures: {
    error: getErrorMessage(payload),
    isLoading: false,
    isLoaded: false
  }
})

const reducer = createReducer(on => {
  on(fetchBrands, handleFetchBrands)
  on(fetchBrandsSuccess, handleFetchBrandsSuccess)
  on(fetchBrandsFailure, handleFetchBrandsFailure)
  on(fetchManufactures, handleFetchManufactures)
  on(fetchManufacturesSuccess, handleFetchFetchManufacturesSuccess)
  on(fetchManufacturesFailure, handleFetchManufacturesFailure)
}, initialState)

export default reducer
