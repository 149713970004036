/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { array, string, number, oneOf, oneOfType, func, bool } from 'prop-types'
import { equals } from 'ramda'
import LazyLoad from 'react-lazyload'
import { connect } from 'react-redux'

import BrandsSlider from 'components/BrandSlider'
import CapPreloader from 'components/CapPreloader'
import {
  fetchBrands as fetchBrandsStore,
  brandsTotalDataSelector,
  getIsLoadedBrandsSelector
} from 'redux/modules/brands'
import { pageLoadedSelector } from 'redux/modules/settings'

const COUNT_HOME = 30
const COUNT_PAGES = 10
const COUNT_CATALOG = 8

@connect(
  ({ brands, reduxAsyncConnect }, { sectionId, type }) => ({
    brands: brandsTotalDataSelector({ type, brands, section: sectionId }),
    isBrandsLoaded: getIsLoadedBrandsSelector({ type, brands, section: sectionId }),
    isPageLoaded: pageLoadedSelector(reduxAsyncConnect)
  }),
  { fetchBrands: fetchBrandsStore }
)
export default class BrandSlider extends PureComponent {
  static propTypes = {
    type: oneOf(['tm', 'license']),
    brands: array,
    sectionId: oneOfType([string, number]),
    page: oneOf(['home', 'catalogList', 'catalogMarks', 'catalog', 'product']),
    sectionName: string,
    fetchBrands: func,
    isBrandsLoaded: bool,
    isPageLoaded: bool
  }

  static defaultProps = {
    sectionId: '',
    type: 'tm',
    sectionName: '',
    brands: [],
    page: 'home',
    isBrandsLoaded: false,
    isPageLoaded: false,
    fetchBrands: () => {}
  }

  onFetchData = params => this.fetchData(params)

  fetchData(sectionId = '') {
    const { fetchBrands, page, type } = this.props
    let prepareParams = {  }
    const countPages = equals(page, 'catalog') ? COUNT_CATALOG : COUNT_PAGES
    const count = equals(page, 'home') ? COUNT_HOME : countPages
    if (sectionId) {
      // string === marks | number === section
      prepareParams = Number.isNaN(Number(sectionId))
        ? { mark_code: sectionId }
        : { section_id: sectionId }
    }
    prepareParams = { ...prepareParams, count, type }
    return fetchBrands(prepareParams)
  }

  render() {
    const {
      brands,
      page,
      type,
      sectionId,
      sectionName,
      isPageLoaded,
      isBrandsLoaded
    } = this.props
    if (!isPageLoaded) {
      return (
        <>
          <CapPreloader
            width='20%'
          />
          <CapPreloader
            height='130px'
          />
        </>
      )
    }
    return (
      <LazyLoad height={200}
        offset={500}
        once>
        <BrandsSlider
          type={type}
          sectionId={sectionId}
          sectionName={sectionName}
          isBrandsLoaded={isBrandsLoaded}
          page={page}
          brands={brands}
          fetchData={this.onFetchData}
        />
      </LazyLoad>
    )
  }
}
